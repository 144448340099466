import React, { Component } from "react"
import { loadableP5 as P5Wrapper } from "./Loadable"
// import Sketch from "./sketch"
// import SketchRedShine from "./SketchRedShine"
import SketchCakes from "./SketchCakes"
import { Helmet } from "react-helmet"
export default class App extends Component {
  render() {
    return (
      <>
        <P5Wrapper sketch={SketchCakes} />
      </>
    )
  }
}
