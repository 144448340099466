import * as d3 from "d3"
import { FaPage4 } from "react-icons/fa"
export default function SketchCakes(p5) {
  //   const kiwimaru = p5.loadFont("KiwiMaru-Light.ttf")
  //   console.log(kiwimaru)
  var globalRatio
  var circles = []
  var startingR
  var currentR
  var maxLoops
  let circlePadding
  let boundedCircleRadius
  let boundedCircleRadiusPadding
  let circleThres = 0
  let slider
  // let sentence = "　　　　エブリシングアバウト東京";
  // let sentence = "　　エブリシングアバウト東京";
  let sentence = "  Donut, Pie & Cake"
  // let sentence = "　　　Everything About Tokyo";
  let sentenceArray = []
  let theta = 0
  let angle = 0
  const fr = 10

  // const skyBlueLight = "rgba(98, 193, 222, 0.3)";
  const skyBlueLight = "rgba(210, 236, 245,1)"
  const skyBlue = "rgba(142, 195, 230,1)"
  const yellow = "rgba(252, 245, 134, 1)"
  const darkBlue = "rgba(56, 102, 154, 1)"
  const lightGray = "rgba(0, 0, 0, 0.8)"
  const lightLightGray = "rgba(148, 148, 148, 0.6)"
  // const lightLightGray = "rgba(255, 255, 255, 0.6)";
  // const lightLightGray = "#c9c9c1";
  const donutConditions = [
    {
      value: 4,
      color: darkBlue,
    },
    {
      value: 6,
      color: skyBlue,
    },

    {
      value: 3,
      // color: "rgba(254, 240, 23, 1)",
      color: yellow,
    },
  ]

  const piConditions = [
    {
      value: 4,
      color: darkBlue,
    },
    {
      value: 6,
      color: skyBlue,
    },

    {
      value: 3,
      color: yellow,
    },
  ]
  var entryMaxValue
  var sliderWidth

  p5.setup = () => {
    const calcCanvasSize = () => {
      const windowWidth = p5.windowWidth
      const windowHeight = p5.windowHeight
      let minDimension = d3.min([windowWidth, windowHeight])
      // let minDimension = windowHeight
      if (1170 <= minDimension) {
        // minDimension = minDimension * 0.9 * 0.6
        minDimension = minDimension * 0.8
      } else if ((676 <= minDimension) & (minDimension <= 1170)) {
        minDimension = minDimension * 0.8
      } else {
        minDimension = minDimension
      }
      return minDimension
    }
    const canvasWidth = calcCanvasSize()
    p5.frameRate(fr)
    // globalRatio = d3.min([canvasWidth / 800, 1])
    globalRatio = d3.min([canvasWidth / 800, 1])
    // globalRatio = 1
    startingR = 100 * globalRatio
    currentR = startingR * globalRatio
    maxLoops = 2000
    circlePadding = 5 * globalRatio
    boundedCircleRadius = 310 * globalRatio
    boundedCircleRadiusPadding = 10 * globalRatio
    circleThres = 0

    entryMaxValue = 10 * globalRatio
    sliderWidth = 150 * globalRatio

    var myCanvas = p5.createCanvas(canvasWidth, canvasWidth)
    // myCanvas.parent("canvasDiv")
    myCanvas.class("myCanvas")
    p5.colorMode(p5.HSB)
    p5.background(skyBlueLight)

    p5.translate(p5.width / 2, p5.height / 2)
    generateCircles()
    circles.forEach((circle, idx) => {
      circles[idx].scaler = d3
        .scaleLinear()
        .domain([0, 10])
        .range([0, circle.r])
    })

    slider = createCSlider(0, 50, 0, 1)
    slider.width = slider.width * globalRatio
    slider.height = slider.height * globalRatio
  }
  p5.mouseClicked = () => {
    startingR = 100 * globalRatio
    currentR = startingR * globalRatio
    maxLoops = 2000
    circles = []
    generateCircles()
    circles.forEach((circle, idx) => {
      circles[idx].scaler = d3
        .scaleLinear()
        .domain([0, 10])
        .range([0, circle.r])
    })
  }

  p5.draw = () => {
    p5.background(skyBlueLight)
    p5.textFont("Helvetica")
    p5.fill("#222")
    p5.textAlign(p5.CENTER, p5.RIGHT)
    p5.textSize(20 * globalRatio)
    p5.text("click to redraw", p5.width * 0.88, p5.height * 0.96)
    p5.fill("#FFF")
    slider.position(80 * globalRatio, p5.height - 50 * globalRatio)

    circleThres = slider.value()

    angle += 0.01
    circles
      .filter(d => d.r >= circleThres)
      .forEach((element, idx) => {
        element.draw(idx)
      })

    p5.push()
    p5.translate(p5.width / 2, p5.height / 2)

    p5.noFill()
    p5.stroke(100)
    p5.strokeWeight(2 * globalRatio)
    // noStroke()
    //   fill(map(this.r, 0, startingR, 0, 255), 255, 255);
    p5.ellipse(
      0,
      0,
      (boundedCircleRadius + boundedCircleRadiusPadding) * 2,
      (boundedCircleRadius + boundedCircleRadiusPadding) * 2
    )

    sentenceArray = sentence.split("")

    for (let i = 0; i < sentenceArray.length; i++) {
      p5.rotate(p5.QUARTER_PI / 11.5) // rotation for the group of letters, which affects the spacing between letters
      p5.push()
      p5.translate(
        (boundedCircleRadius + boundedCircleRadiusPadding + 22 * globalRatio) *
          p5.sin(theta + p5.PI),
        (boundedCircleRadius + boundedCircleRadiusPadding + 22 * globalRatio) *
          p5.cos(theta + p5.PI)
      )
      p5.rotate(2 * p5.PI) // rotation for individual letter
      p5.fill(lightLightGray)
      p5.textAlign(p5.CENTER, p5.CENTER)
      p5.textSize(32 * globalRatio)
      // textFont("RocknRoll One");
      // textFont("Kiwi Maru");
      p5.textFont("Helvetica")
      p5.text(sentenceArray[i], 0, 0)
      p5.pop()
    }
    p5.pop()
  }

  function generateCircles() {
    currentR = startingR
    var loopCycle = maxLoops

    while (loopCycle > 0) {
      var circle = new Circle(
        p5.random(-p5.width / 2, p5.width / 2),
        p5.random(-p5.height / 2, p5.height / 2),
        currentR
      ) // 適当に配置してみて，それが入るかどうかチェックする感じみたい．

      var available = true
      for (var j = 0; j < circles.length; j++) {
        var other = circles[j]
        //   console.log(dist(circle.x, circle.y, 0, 0));
        if (
          p5.dist(circle.x, circle.y, other.x, other.y) <
            circle.r + other.r + circlePadding ||
          p5.dist(circle.x, circle.y, 0, 0) + circle.r >= boundedCircleRadius
        ) {
          available = false
          break
        }
      }

      if (circles.length == 0) {
        if (
          p5.dist(circle.x, circle.y, 0, 0) + circle.r >=
          boundedCircleRadius
        ) {
          available = false
        }
      }

      if (available) {
        circles.push(circle)
        if (currentR >= 50 * globalRatio) {
          currentR += -2 * globalRatio
        }
        //   loopCycle = maxLoops;
        if (currentR <= 0) {
          loopCycle = 0
        }
      }

      loopCycle += -1
      if (loopCycle === 0) {
        if (currentR > 0) {
          currentR += -1 * globalRatio
          loopCycle = maxLoops
        }
      }
    }

    while (true) {
      let haveSpace = false
      circles.forEach((elementThis, idxThis) => {
        const dists = circles.map((elementOther, idxOther) => {
          if (idxThis != idxOther) {
            return (
              p5.dist(
                elementThis.x,
                elementThis.y,
                elementOther.x,
                elementOther.y
              ) -
              (elementThis.r + elementOther.r + circlePadding)
            )
          } else {
            return 1000
          }
        })
        let minLengthMinusRadiusWithPadding = Math.min(...dists)
        if (
          minLengthMinusRadiusWithPadding >= 1 * globalRatio &&
          p5.dist(elementThis.x, elementThis.y, 0, 0) + elementThis.r <=
            boundedCircleRadius &&
          elementThis.r <= 50 * globalRatio
        ) {
          elementThis.r += 1 * globalRatio
          haveSpace = true
        }
      })
      if (haveSpace == false) {
        break
      }
    }
  }

  function Circle(x, y, r) {
    this.x = x
    this.y = y
    this.r = r
    this.scaler = f => f
  }

  Circle.prototype.draw = function (idx) {
    p5.push()

    p5.translate(p5.width / 2, p5.height / 2)
    p5.rotate(angle * p5.PI)

    p5.noStroke()
    // fill(map(this.r, 0, startingR, 0, 255), 255, 255);
    p5.ellipse(this.x, this.y, this.r * 2, this.r * 2)
    const radiusGirds = [2, 5, 8]
    radiusGirds.forEach((radiusGrid, idx) => {
      const r = this.scaler(radiusGrid)
      p5.strokeWeight(0.5)
      p5.noFill()
      p5.stroke("rgba(0, 0, 0, 0.2)")

      p5.ellipse(this.x, this.y, r * 2, r * 2)
    })
    const Numticks = 12
    ;[...Array(12)].forEach((_, idx) => {
      p5.push()
      p5.translate(this.x, this.y)
      p5.strokeWeight(this.scaler(0.25))
      p5.line(
        this.scaler(7.8) * p5.cos(((2 * p5.PI) / Numticks) * idx),
        this.scaler(7.8) * p5.sin(((2 * p5.PI) / Numticks) * idx),
        this.scaler(8.3) * p5.cos(((2 * p5.PI) / Numticks) * idx),
        this.scaler(8.3) * p5.sin(((2 * p5.PI) / Numticks) * idx)
      )
      p5.pop()
    })

    if (idx % 3 == 0) {
      p5.noStroke()
      let angleStart = -p5.HALF_PI // start at the top
      piConditions.forEach((entry, idx) => {
        p5.fill(entry.color)
        let wedgeSize = p5.map(
          entry.value * globalRatio,
          0,
          entryMaxValue,
          0,
          p5.TAU
        )
        let angleStop = angleStart + wedgeSize
        p5.arc(
          this.x,
          this.y,
          this.scaler(6.5) * 2,
          this.scaler(6.5) * 2,
          angleStart,
          angleStop
        )
        angleStart = angleStop
      })
      // // knock a hole out of the middle
      // fill(255);
      // ellipse(this.x, this.y, this.scaler(4) * 2, this.scaler(4) * 2);
    } else if (idx % 3 == 1) {
      p5.noStroke()
      p5.fill(skyBlue)
      // stroke("rgba(0, 0, 0, 0.2)");
      p5.ellipse(this.x, this.y, this.scaler(7) * 2, this.scaler(7) * 2)

      // ICING YUMMY :O
      p5.fill(darkBlue)
      // stroke("rgba(0, 0, 0, 0.2)");
      p5.ellipse(this.x, this.y, this.scaler(5.8) * 2, this.scaler(5.8) * 2)

      // OTHER DOUGHNUT
      p5.fill(yellow)
      p5.ellipse(this.x, this.y, this.scaler(4.5) * 2, this.scaler(4.5) * 2)

      // DOUGHNUT HOLE
      p5.fill("rgba(255, 255, 255, 1)")
      // stroke("rgba(0, 0, 0, 0.2)");
      p5.ellipse(this.x, this.y, this.scaler(3) * 2, this.scaler(3) * 2)
    } else {
      p5.noStroke()
      let angleStart = -p5.HALF_PI // start at the top
      donutConditions.forEach((entry, idx) => {
        p5.fill(entry.color)
        let wedgeSize = p5.map(
          entry.value * globalRatio,
          0,
          entryMaxValue,
          0,
          p5.TAU
        )
        let angleStop = angleStart + wedgeSize
        p5.arc(
          this.x,
          this.y,
          this.scaler(6.5) * 2,
          this.scaler(6.5) * 2,
          angleStart,
          angleStop
        )
        angleStart = angleStop
      })
      // knock a hole out of the middle
      p5.fill(255)
      p5.ellipse(this.x, this.y, this.scaler(4) * 2, this.scaler(4) * 2)
    }
    // p5.fill(lightGray)
    // p5.stroke(100)
    // p5.strokeWeight(2)
    // p5.textSize(this.scaler(2))
    // p5.textAlign(p5.CENTER, p5.CENTER)
    // p5.text(this.r, this.x, this.y)
    p5.pop()

    p5.push()
    // p5.textFont("Kiwi Maru")
    p5.textFont("Helvetica")
    p5.fill(lightLightGray)
    p5.textAlign(p5.CENTER, p5.RIGHT)
    p5.textSize(this.scaler(1))
    p5.text("more", 50 * globalRatio, p5.height - 40 * globalRatio)
    p5.textAlign(p5.CENTER, p5.LEFT)
    p5.textSize(this.scaler(1))
    p5.text(
      "less",
      90 * globalRatio + sliderWidth,
      p5.height - 40 * globalRatio
    )
    p5.pop()
  }

  function createCSlider(a, b, c, d) {
    const r = new CSlider(a, b, c, d)
    return r
  }

  class CSlider {
    constructor(min, max, value = (min + max) / 2, step = 1) {
      this.width = 135
      this.height = 15
      let widthtoheight = this.width - this.height
      this.ratio = this.width / widthtoheight
      this.x = 10
      this.y = -1000
      this.spos = this.x + this.width / 2 - this.height / 2
      this.newspos = this.spos
      this.sposMin = this.x
      this.sposMax = this.x + this.width - this.height
      this.vmin = min
      this.vmax = max
      this.svalue = p5.constrain(value, this.vmin, this.vmax)
      this.vstep = step
      this.loose = 1
      this.over = false
      this.locked = false
      this.scale = 1
    }

    update() {
      if (this.overEvent()) {
        this.over = true
      } else {
        this.over = false
      }
      if (p5.mouseIsPressed && this.over) {
        this.locked = true
      }
      if (!p5.mouseIsPressed) {
        this.locked = false
      }
      if (this.locked) {
        this.newspos = p5.constrain(
          p5.mouseX / this.scale - this.height / 2,
          this.sposMin,
          this.sposMax
        )
        this.svalue =
          this.vmin +
          (this.vmax - this.vmin) *
            ((this.newspos - this.sposMin) / (this.sposMax - this.sposMin))
        if (this.vstep > 0) {
          this.svalue = p5.constrain(
            this.vmin +
              p5.round((this.svalue - this.vmin) / this.vstep) * this.vstep,
            this.vmin,
            this.vmax
          )
        }
        this.newspos =
          this.x +
          (this.width - this.height) *
            ((this.svalue - this.vmin) / (this.vmax - this.vmin))
      }
      if (p5.abs(this.newspos - this.spos) > 1) {
        this.spos = this.spos + (this.newspos - this.spos) / this.loose
      }
    }

    overEvent() {
      if (
        p5.mouseX / this.scale > this.x &&
        p5.mouseX / this.scale < this.x + this.width &&
        p5.mouseY / this.scale > this.y &&
        p5.mouseY / this.scale < this.y + this.height
      ) {
        return true
      } else {
        return false
      }
    }

    display() {
      p5.noStroke()
      p5.fill(204)
      p5.rect(this.x, this.y, this.width, this.height)
      p5.fill(darkBlue)
      p5.rect(this.spos, this.y, this.height, this.height)
    }

    getPos() {
      // Convert spos to be values between
      // 0 and the total width of the scrollbar
      return this.spos * this.ratio
    }

    value() {
      return this.svalue
    }

    setScale(sc) {
      this.scale = sc
    }

    position(xp, yp) {
      this.x = xp
      this.y = yp
      if (this.vstep > 0) {
        this.svalue = p5.constrain(
          this.vmin +
            p5.round((this.svalue - this.vmin) / this.vstep) * this.vstep,
          this.vmin,
          this.vmax
        )
      }
      this.spos =
        this.x +
        (this.width - this.height) *
          ((this.svalue - this.vmin) / (this.vmax - this.vmin))
      //console.log(this.smin);
      this.newspos = this.spos
      this.sposMin = this.x
      this.sposMax = this.x + this.width - this.height
      p5.push()
      this.update()
      this.display()
      p5.pop()
    }
  }
}
